import React, { useState, useEffect, useLayoutEffect } from "react"
import Lottie from "react-lottie"
import { navigate } from "gatsby"

import { Card } from "../card/card"
import SocmedLinks from "../socmed-links/socmed-links"
import { Link } from "../utils/utils"

import burgerMenu from "../../assets/lotties/burger_menu_click.json"
import closeMenu from "../../assets/lotties/burger_close_click.json"
import { Container } from "../grid/grid"
import { Button } from "../buttons/buttons"

import { useAuthContext } from "../../context/authContext"

const sapKey = process.env.SAP_API_KEY

const NavbarMobile = ({
  brandLogoMain,
  brandLogoSecondary,
  brandLogoAlt,
  url,
  navBackground,
  fetchedData,
  lang = "EN",
}) => {
  const [toggleMobile, setToggleMobile] = useState(false)
  const menuItems = lang === "EN"
  ? fetchedData.menus.nodes[2].menuItems.nodes
  : fetchedData.menus.nodes[3].menuItems.nodes
  const propertyTypes = fetchedData.propertyTypes.nodes
  const { user, setUser } = useAuthContext()
  const [loggedIn, setLoggedIn] = useState(user !== null)

  const [developmentToggle, setDevelopmentToggle] = useState({
    clicked: false,
  })
  
  const [corporateToggle, setCorporateToggle] = useState({
    clicked: false,
  })

  const [investorToggle, setInvestorToggle] = useState({
    clicked: false,
  })

  const handleDevelopmentClick = () => {
    if (developmentToggle.clicked === false) {
      setDevelopmentToggle({ clicked: true })
    } else {
      setDevelopmentToggle({ clicked: false })
    }
  }

  const handleCorporateClick = () => {
    if (corporateToggle.clicked === false) {
      setCorporateToggle({ clicked: true })
    } else {
      setCorporateToggle({ clicked: false })
    }
  }

  const handleInvestorClick = () => {
    if (investorToggle.clicked === false) {
      setInvestorToggle({ clicked: true })
    } else {
      setInvestorToggle({ clicked: false })
    }
  }

  useLayoutEffect(() => {
    // load SAP cdn at header
    ;(function loadSAPCdn() {
      var script = document.createElement("script")
      script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`
      document.getElementsByTagName("script")[0].parentNode.appendChild(script)
    })()
  }, [])

  const mobileToggler = {
    loop: false,
    autoplay: true,
    animationData: toggleMobile ? burgerMenu : closeMenu,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const showMenu = toggleMobile ? "show" : ""

  let items = []

  for (let i = propertyTypes.length - 1; i >= 0; i--) {
    switch (propertyTypes[i].slug) {
      case "township":
        items[0] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px card-bg-img-top"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "residential":
        items[1] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "office":
        items[2] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "retail":
        items[3] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "hospitality":
        items[4] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "commercial":
        items[5] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px card-bg-img-top"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      case "industrial":
        items[6] = (
          <li key={`slider-${propertyTypes[i].id}`} className="glide__slide">
            <Card
              variant="overlay"
              title={propertyTypes[i].name}
              titleClassName="h5 text-truncate"
              text={
                propertyTypes[i].description.length > 0
                  ? `${propertyTypes[i].description.substr(0, 100)}...`
                  : propertyTypes[i].description
              }
              textClassName="font-size-sm"
              img={propertyTypes[i].acfPropertyType.image.sourceUrl}
              link={`/development/${propertyTypes[i].slug.toLowerCase()}`}
              imgHeight="h-150px"
              className="mb-3 card-nav"
              imgOverlay="bottom"
            />
          </li>
        )
        break
      default:
        break
    }
  }

  const urlArr = url ? url.split("/") : null
  const toggleHandler = () => {
    const mainElmnt = document.querySelector("main")
    setToggleMobile(!toggleMobile)
    if (toggleMobile) {
      mainElmnt.classList.remove("overflow-hidden")
    } else if (!toggleMobile) {
      setTimeout(() => mainElmnt.classList.add("overflow-hidden"), 200)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.gigya.accounts.addEventHandlers({
        onLogin: eventData => {
          setUser(eventData.profile)
          navigate("/profile")
        },
      })
    }, 1000)
  })

  useEffect(() => {
    setLoggedIn(user !== null)
  }, [user])

  const logoutResponse = response => {
    if (response.errorCode == 0) {
      navigate("/login")
      setUser(null)
    } else {
      alert("Error :" + response.errorMessage)
    }
  }

  const handlerLogout = () => {
    window.gigya.accounts.logout({
      callback: logoutResponse,
    })
  }

  return (
    <div id="navbarMobile">
      <div className="navbar-toggle-wrapper fixed-top">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleHandler}
        >
          <Lottie options={mobileToggler} width={30} height={30} />
        </button>
      </div>
      <nav className={`navbar navbar-nav-main ${navBackground} fixed-top`}>
        <Link className="navbar-brand" to="/">
          <img className="logo-main" src={brandLogoMain} alt={brandLogoAlt} />
          <img
            className="logo-secondary"
            src={brandLogoSecondary}
            alt={brandLogoAlt}
          />
        </Link>
      </nav>

      {/* Mobile Menu*/}
      <div className={`navbar-collapse ${showMenu} fixed-top`}>
        <div className="container">
          <div className="navbar-collapse-header">
            <form
              className="form-control-search-wrapper"
              action="/search-result"
            >
              <input
                type="text"
                name="key"
                className="form-control form-control-search"
                placeholder="Search"
                id="navMobileSearch"
              />
            </form>
          </div>
          <div className="navbar-collapse-content">
            <ul className="navbar-nav">
              {loggedIn ? (
                <>
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/profile">
                      Profile
                    </Link>
                  </li>
                  {/* <li className={`nav-item`}>
                      <Link className="nav-link" to="/forgot-password">
                        Forgot Password
                      </Link>
                    </li> */}
                  <li className={`nav-item`}>
                    <span className="nav-link" onClick={() => handlerLogout()}>
                      Logout
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className={`nav-item`} id={`Development`} data-target={`Development`} onClick={() => handleDevelopmentClick()}>
                    <a className="nav-link">
                      Development
                    </a>
                  </li>
                  <li className={`nav-item`} id={`Corporate`} data-target={`Corporate`} onClick={() => handleCorporateClick()}>
                    <a className="nav-link">
                      Corporate
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/sustainability">
                      Sustainability
                    </Link>
                  </li>
                  <li className={`nav-item`} id={`Investors`} data-target={`Investors`} onClick={() => handleInvestorClick()}>
                    <a className="nav-link">
                      Investor
                    </a>
                  </li>
                </>
              )}
            </ul>
            <div className="row mt-3">
              <div className="col-6">
                <Link className="btn btn-primary btn-block nav-primary pb-0 pt-1 register" to="/register">
                  Register
                </Link>
              </div>
              <div className="col-6">
                <Link className="btn btn-primary btn-block nav-primary pb-0 pt-1 subscribe" to="/subscribe">
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* development dropdown menu */}
      <div
        id={`Development`}
        className={`navbar-slide fixed-top ${lang === "EN" && developmentToggle.clicked ? "show" : ""}`}
        data-content
      >
        <div className="navbar-slide-header">
          <div className="container">
            <button id="navBack" className="btn btn-link text-dark" onClick={() => handleDevelopmentClick()}>
              <i className="fa fa-arrow-left"></i>
            </button>
            <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link p-0 m-0">
                  {lang === "EN" ? "Development" : "Pengembangan"}
                </span>
              </li>
            </ul>
            <div>
              <a
                href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-link fx-underline pt-3 pb-0"
              >
                e-Catalog
              </a>
            </div>
            <Link
              to={lang === "EN" ? "/development" : "/id/development"}
              className="btn btn-link"
            >
              {lang === "EN" ? "See All Projects" : "Lihat Semua Proyek"}
            </Link>
          </div>
        </div>
        <Container>
          <ul className="nav-project-mobile list-unstyled mt-4">{items}</ul>
        </Container>
      </div>

      {/* corporate dropdown menu */}
      <section
        id={`Corporate`}
        className={`navbar-slide fixed-top ${lang === "EN" && corporateToggle.clicked ? "show" : ""}`}
        data-content
      >
        <div className="navbar-slide-header">
          <div className="container">
            <button id="navBack" className="btn btn-link text-dark" onClick={() => handleCorporateClick()}>
              <i className="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
        <div className="container">
          <ul className="navbar-nav mb-4">
            <li className="nav-item active">
              <span className="nav-link">
                {lang === "EN" ? "About" : "Tentang"}
              </span>
              <ul className="navbar-nav mb-3">
                {menuItems[2].childItems.nodes[0] !== undefined &&
                  menuItems[2].childItems.nodes[0].childItems.nodes.map(
                    (item, i) => {
                      return (
                        <div
                          key={`submenu-mobile-${item.label}`}
                          className="nav-item"
                        >
                          <Link
                          to={`${
                            lang === "EN"
                              ? item.url
                              : item.label.toLowerCase() === "e-catalog" 
                              || item.label.toLowerCase() === "double dream"
                              ? item.url
                              : `/id${item.url}`
                          }`}
                          className="nav-link"
                        >
                          {item.label}
                        </Link>
                        </div>
                      )
                    }
                  )}
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to={lang === "EN" ? "/partner" : "/id/partner"}
                className="nav-link"
              >
                {lang === "EN" ? "Partners" : "Partner"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={lang === "EN" ? "/news" : "/id/news"}
                className="nav-link"
              >
                {lang === "EN" ? "News" : "Berita"}
              </Link>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://events.sinarmasland.com/"
                className="nav-link"
              >
                {lang === "EN" ? "Events" : "Acara"}
              </a>
            </li>
          </ul>

          <Link
            to={lang === "EN" ? "/career" : "/id/career"}
            className="btn btn-outline-dark mb-3"
          >
            <span>{lang === "EN" ? "Career" : "Karir"}</span>
          </Link>
          <Link
            to={lang === "EN" ? "/" : "/id"}
            className="btn btn-outline-dark"
          >
            <span>
              {lang === "EN" ? "Vendor Registration" : "Pendaftaran Vendor"}
            </span>
          </Link>
        </div>
      </section>

      {/* investor dropdown menu */}
      <section
        id={`Investors`}
        className={`navbar-slide fixed-top ${lang === "EN" && investorToggle.clicked ? "show" : ""}`}
        data-content
      >
        <div className="navbar-slide-header">
          <div className="container">
            <button id="navBack" className="btn btn-link text-dark" onClick={() => handleInvestorClick()}>
              <i className="fa fa-arrow-left" />
            </button>
          </div>
        </div>
        <div className="container">
          <ul className="navbar-nav">
            {menuItems[4] &&
              menuItems[4].childItems.nodes.map((item, i) => (
                <li
                  key={`investors-menu-mobile-${item.label}`}
                  className="nav-item"
                >
                  <Link
                    className="nav-link"
                    to={
                      item.url === "/general-information"
                        ? lang === "EN"
                          ? `/investors`
                          : "/id/investors"
                        : item.url === "/about-us/board-of-directors"
                        ? lang === "EN"
                          ? `${item.url}`
                          : `/id/${item.url}`
                        : item.url === "/living-lab-ventures"
                        ? lang === "EN"
                          ? `${item.url}`
                          : `/id/${item.url}`
                        : lang === "EN"
                        ? `/investors${item.url}`
                        : `/id/investors${item.url}`
                    }
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </section>
    </div>
  )
}

export default NavbarMobile
